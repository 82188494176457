var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"management-center"}},[_c('b-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('h2',{staticClass:"app-color"},[_vm._v("訂正電影記錄")]),_c('div',{staticStyle:{"border-bottom":"2px solid #0e72bc","margin-bottom":"20px"}}),_c('div',[_c('b-button',{staticClass:"handle-button",attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":_vm.back}},[_vm._v(" 回上頁 ")])],1),_c('hr'),_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_vm._v(" 院線： ")]),_c('b-col',{attrs:{"cols":"10"}},[_vm._v(_vm._s(_vm.filmInfo.circuit))]),_c('b-col',{attrs:{"cols":"2"}},[_vm._v(" Circuit POS ID: ")]),_c('b-col',{attrs:{"cols":"10"}},[_vm._v(_vm._s(_vm.pos_id)+" "),_c('b-button',{staticClass:"ml-5",attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":_vm.openPosid}},[_vm._v("修改POS ID")])],1),_c('b-col',{attrs:{"cols":"2"}},[_vm._v(" 院線提供之電影名稱: ")]),_c('b-col',{attrs:{"cols":"10"}},[_vm._v(_vm._s(_vm.filmInfo.title_name))])],1)],1),_c('hr'),_c('h3',{staticClass:"app-color"},[_vm._v(" "+_vm._s(_vm.items && _vm.items.length > 0 ? "已在案的類似POS ID" : "沒有類似POS ID")+" ")]),_c('hr'),(_vm.items && _vm.items.length > 0)?_c('div',[_c('b-table',{attrs:{"bordered":"","hover":"","responsive":"","fields":_vm.$store.state.highlightCol.firTable
            ? _vm.$store.state.newTableFields
            : _vm.fields,"items":_vm.items},on:{"head-clicked":function($event){return _vm.commonHighLightCol(
            $event,
            _vm.fields,
            _vm.$store.state.newTableFields,
            'firTable'
          )}},scopedSlots:_vm._u([{key:"cell(effect)",fn:function(row){return [(row.item.film.remark_1)?_c('span',[_vm._v(_vm._s(row.item.film.remark_1)+" ")]):_vm._e(),(row.item.film.remark_2)?_c('span',[_vm._v("["+_vm._s(row.item.film.remark_2)+"] ")]):_vm._e(),(row.item.film.remark_3)?_c('span',[_vm._v("["+_vm._s(row.item.film.remark_3)+"] ")]):_vm._e()]}},{key:"cell(actions)",fn:function(row){return [_c('b-button',{staticClass:"handle-button",attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.addToOthorPosId(row.item.id, row.item.pos_id)}}},[_vm._v(" 儲存在此POS ID ")])]}}],null,false,685982945)})],1):_vm._e(),_c('b-modal',{attrs:{"size":"lg","id":"add-pos-id","hide-footer":"","centered":"","title":"Change POS ID"}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingAddPosid),expression:"loadingAddPosid"}],staticClass:"my-1"},[_c('b-form-input',{staticClass:"mb-1",attrs:{"trim":"","placeholder":"Enter POS ID"},on:{"change":_vm.checkPosid},model:{value:(_vm.add_pos_id),callback:function ($$v) {_vm.add_pos_id=$$v},expression:"add_pos_id"}}),(_vm.usedPosidItems && _vm.usedPosidItems.length > 0)?_c('div',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.usedPosidItems,"fields":_vm.usedPosidFields}})],1):_vm._e(),(_vm.usePosid)?_c('div'):_vm._e()],1),_c('div',{staticClass:"float-right"},[(_vm.usePosid)?_c('b',{staticClass:"mr-1",staticStyle:{"color":"red"}},[_vm._v("此POS ID未曾被该戲院使用，可確認更改")]):_vm._e(),_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.cancelPosid}},[_vm._v("取消")]),_c('b-button',{attrs:{"variant":"primary","size":"sm","disabled":!_vm.usePosid},on:{"click":function($event){return _vm.addPosID(_vm.add_pos_id)}}},[_vm._v("確認")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }