<template>
  <section id="management-center">
    <b-card v-loading="loading">
      <h2 class="app-color">訂正電影記錄</h2>
      <div style="border-bottom: 2px solid #0e72bc; margin-bottom: 20px"></div>
      <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button"
          @click="back"
        >
          回上頁
        </b-button>
      </div>
      <hr />
      <div>
        <b-row>
          <b-col cols="2"> 院線： </b-col>
          <b-col cols="10">{{ filmInfo.circuit }}</b-col>
          <b-col cols="2"> Circuit POS ID: </b-col>
          <b-col cols="10"
            >{{ pos_id }}
            <b-button
              @click="openPosid"
              size="sm"
              class="ml-5"
              variant="outline-secondary"
              >修改POS ID</b-button
            ></b-col
          >
          <b-col cols="2"> 院線提供之電影名稱: </b-col>
          <b-col cols="10">{{ filmInfo.title_name }}</b-col>
        </b-row>
      </div>
      <hr />
      <h3 class="app-color">
        {{
          items && items.length > 0 ? "已在案的類似POS ID" : "沒有類似POS ID"
        }}
      </h3>
      <hr />
      <div v-if="items && items.length > 0">
        <b-table
          bordered
          hover
          responsive
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : fields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              fields,
              $store.state.newTableFields,
              'firTable'
            )
          "
          :items="items"
        >
          <template v-slot:cell(effect)="row">
            <span v-if="row.item.film.remark_1"
              >{{ row.item.film.remark_1 }}
            </span>
            <span v-if="row.item.film.remark_2"
              >[{{ row.item.film.remark_2 }}]
            </span>
            <span v-if="row.item.film.remark_3"
              >[{{ row.item.film.remark_3 }}]
            </span>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button
              @click="addToOthorPosId(row.item.id, row.item.pos_id)"
              size="sm"
              variant="outline-secondary"
              class="handle-button"
            >
              儲存在此POS ID
            </b-button>
          </template>
        </b-table>
      </div>
      <!-- <div class="d-flex mt-1 justify-content-end">
        <div>
          <p></p>
          <p>
            <b-button size="sm" variant="outline-primary"
              >刪除此電影記錄</b-button
            >
          </p>
        </div>
      </div> -->
      <b-modal
        size="lg"
        id="add-pos-id"
        hide-footer
        centered
        title="Change POS ID"
      >
        <div class="my-1" v-loading="loadingAddPosid">
          <b-form-input
            trim
            class="mb-1"
            v-model="add_pos_id"
            @change="checkPosid"
            placeholder="Enter POS ID"
          ></b-form-input>

          <div v-if="usedPosidItems && usedPosidItems.length > 0">
            <b-table
              striped
              hover
              :items="usedPosidItems"
              :fields="usedPosidFields"
            ></b-table>
          </div>
          <div v-if="usePosid"></div>
        </div>
        <div class="float-right">
          <b style="color: red" v-if="usePosid" class="mr-1"
            >此POS ID未曾被该戲院使用，可確認更改</b
          >
          <b-button class="mr-1" @click="cancelPosid" size="sm">取消</b-button>
          <b-button
            variant="primary"
            size="sm"
            :disabled="!usePosid"
            @click="addPosID(add_pos_id)"
            >確認</b-button
          >
        </div>
      </b-modal>
    </b-card>
  </section>
</template>
<script>
import {
  getFilmByPosid,
  savePosId,
  checkPosid,
  addToOthorPosId,
} from "@/api/management.js"
import SpecialFilmRecord from "../report/specialFilmRecord.vue"

export default {
  components: {},
  data() {
    return {
      loadingAddPosid: false,
      usedPosidItems: [],
      usedPosidFields: [
        { key: "film_id", label: "Film ID" },
        { key: "system_name", label: "在系統中的電影名字" },
        { key: "title_name", label: "Film Name" },
        { key: "film.ver_name", label: "Version" },
        { key: "circuit_name", label: "Circuit Name" },
      ],
      loading: false,
      add_pos_id: "",
      fields: [
        { key: "pos_id", label: "POS ID", sortable: true },
        {
          key: "effect",
          label: "Effect",
          sortable: true,
        },
        {
          key: "circuit_name",
          label: "Title stated by Circuit (Run)",
          sortable: true,
        },
        { key: "actions", label: "" },
      ],

      items: [],
      pos_id: "",
      filmInfo: {
        circuit: "",
        pos_id: "",
        title_name: "",
        full_name: "",
      },
      usePosid: false,
    }
  },
  methods: {
    addToOthorPosId(id, posid) {
      this.$bvModal.hide("add-pos-id")
      this.loading = true
      const recordId =
        this.filmInfo.type === "abnormal"
          ? this.filmInfo.film_circuit_id
          : this.filmInfo.id
      addToOthorPosId(recordId, id)
        .then((res) => {
          console.log(res)
          window.sessionStorage.setItem("pos_id", posid)
          this.$router.push({
            name: "revise-record-save",
            query: {
              pos_id: posid,
              id: this.$route.query.id,
            },
          })
          this.loading = false
          if (posid === this.$route.query.pos_id) {
            this.$router.go(0)
          }
        })
        .catch((error) => {
          this.loading = false
        })
    },
    openPosid() {
      this.$bvModal.show("add-pos-id")
      this.add_pos_id = ""
      this.usedPosidItems = []
      this.usePosid = false
    },
    cancelPosid() {
      this.add_pos_id = ""
      this.usePosid = false
      this.$bvModal.hide("add-pos-id")
      this.loadingAddPosid = false
    },
    checkPosid() {
      this.loadingAddPosid = true
      console.log(this.$route.query.circuit_id)
      const data = {
        pos_id: this.add_pos_id,
        circuit_id: this.$route.query.circuit_id,
      }
      console.log(data)
      checkPosid(data).then((res) => {
        this.usedPosidItems = res.data.list
        this.usedPosidItems.map((item) => {
          item.system_name =
            item.film && item.film.title_name ? item.film.title_name : "-"
        })
        this.usePosid = res.data.valid
        this.loadingAddPosid = false
      })
    },
    addPosID(pos_id) {
      if (!pos_id) {
        this.$message({
          message: "請輸入一個POS ID",
        })
      } else {
        this.savePosId(pos_id)
      }
    },
    savePosId(posid) {
      this.$bvModal.hide("add-pos-id")
      this.loading = true
      console.log(this.filmInfo, " this.filmInfo.pos_id")

      const data = {
        pos_id: this.pos_id,
        new_pos_id: posid,
        film_id: this.filmInfo.film_id,
        circuit_id: this.filmInfo.circuit_id,
      }
      const id =
        this.filmInfo.type === "notfound"
          ? this.$route.query.id
          : this.filmInfo.film_circuit_id
      savePosId(data)
        .then((res) => {
          console.log(res)
          const filmInfo = {
            cinema_id: this.filmInfo.cinema_id,
            circuit: this.filmInfo.circuit,
            circuit_id: this.filmInfo.circuit_id,
            pos_id: posid,
            title_name: this.filmInfo.title_name,
            search_name: this.filmInfo.search_name,
            film_id: this.filmInfo.film_id,
            id:
              this.filmInfo.type === "notfound"
                ? res.data.id
                : this.filmInfo.id,
            goPage: this.filmInfo.goPage,
            type: this.filmInfo.type,
            film_circuit_id:
              this.filmInfo.type === "notfound"
                ? this.filmInfo.film_circuit_id
                : res.data.id,
          }
          sessionStorage.setItem("filmInfo", JSON.stringify(filmInfo))
          this.$router.push({
            name: "revise-record-save",
            query: {
              pos_id: posid,
              id: res.data.id,
              circuit_id: this.filmInfo.circuit_id,
            },
          })
          this.loading = false
          if (posid === this.$route.query.pos_id) {
            this.$router.go(0)
          }
        })
        .catch((error) => {
          this.loading = false
        })
    },
    getFilmByPosid() {
      this.loading = true
      getFilmByPosid(this.pos_id)
        .then((res) => {
          console.log(res)
          this.items = res.data
          res.data.map((el, index) => {
            this.items[
              index
            ].circuit_name = `${el.circuit_name}[${el.film.run_ver.run_ver}]`
          })
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    back() {
      if (this.$route.query.regionUrl) {
        this.$router.push(this.filmInfo.goPage)
      } else {
        this.$router.push({
          name: "revise-record-add",
          query: {
            pos_id: this.filmInfo.pos_id,
            master_id: this.filmInfo.master_id,
            search_name: this.filmInfo.search_name,
            circuit_id: this.filmInfo.circuit_id,
            id: this.filmInfo.id,
            title_name: this.filmInfo.title_name,
          },
        })
      }
    },
  },
  created() {
    this.pos_id = this.$route.query.pos_id
    this.filmInfo = JSON.parse(sessionStorage.getItem("filmInfo"))
    this.filmInfo.pos_id = this.filmInfo.type === "abnormal" ? "" : ""
    this.getFilmByPosid()
  },
}
</script>
<style lang="scss" scope="scoped">
.app-color {
  font-weight: 500;
  color: #0e72bc;
  font-size: 20px;
}
.line {
  height: 3px;
}
.lh30 {
  width: 250px;
}
.table-scroll {
  white-space: nowrap;
}
.font-color {
  color: blue;
}
</style>
